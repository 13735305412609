<template>
  <div class="web_box">
    <van-nav-bar
      title="硬件商城选项"
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div class="bg"></div>
    <div class="content_box">
      <router-link :to="{path: '/shoppingList', query: {active: 1}}" replace>
        <div class="item_box">
          <img src="../../assets/mall/icon1.png" alt="" />
          <span>便携</span>
        </div>
      </router-link>
      <router-link :to="{path: '/shoppingList', query: {active: 2}}" replace>
        <div class="item_box">
          <img src="../../assets/mall/icon2.png" alt="" />
          <span>家庭</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/mall/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
  top: 0;
  left: 0;
}
.item_box {
  width: 690px;
  height: 240px;
  margin: 20px auto 0;
  background-color: rgba($color: #002944, $alpha: 0.5);
  line-height: 240px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
  }
  span {
    font-size: 30px;
    margin-left: 40px;
    color: #00ccff;
  }
}
.content_box {
  margin-top: 108px;
}
</style>
<script>
export default {};
</script>
